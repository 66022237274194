<template>
  <v-card>
    <v-row>
      <v-col cols="12" :md="contentMd" :class="contentMd === 12 ?'pb-0' : 'md:pr-0'">
        <v-card-text :class="{'flex h-full': props.fillHeight}">
          <slot name="content"/>
        </v-card-text>
      </v-col>
      <v-col v-bind="actionCols" :class="actionCols.md === 12 ? 'pt-0' : 'md:pl-0'">
        <div :class="{'vertical' : contentMd === 12}" class="bg-background credit-value-card">
          <v-card-text class="h-full flex">
            <slot name="action"/>
          </v-card-text>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts" setup>
type AutoWidth = 'auto';
type ColsProps = {
  cols: number,
  md: number | AutoWidth
};

const props = withDefaults(defineProps<{
  contentMd?: number | AutoWidth,
  actionsMd?: number | AutoWidth | null,
  fillHeight?: boolean,
}>(), {
  contentMd: 8,
  actionsMd: 4,
  fillHeight: false,
});

const actionCols = computed<Partial<ColsProps>>(() => !props.actionsMd ? {} : {
  cols: 12,
  md: props.actionsMd,
});
</script>

<style scoped lang="scss">
@import '@/assets/styles/vuetify/base/breakpoints/index';

.credit-value-card {
  /* TODO do zaorania jak vuetify naprawi useDisplay mdAndUp pod kątem hydracji */
  @apply flex items-end text-center h-full max-md:rounded-b-sm;
  
  &.vertical {
    border-block-start-width: thin !important;
    border-block-start-style: solid !important;
    border-block-start-color: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
  }

  &:not(.vertical) {
    @apply md:rounded-r-sm;
    border-inline-start-width: thin !important;
    border-inline-start-style: solid !important;
    border-inline-start-color: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
  }

  @include tablet {
    border-block-start-width: thin !important;
    border-block-start-style: solid !important;
    border-block-start-color: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
  }
}
</style>
